// ** packages **
import { useCallback, useEffect, useRef, useState } from "react";
import { Search, Plus } from "react-feather";
import { debounce } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// ** components **
import Breadcrumbs from "components/Breadcrumbs";
import DataTableReact, { TableRefType } from "components/DataTable";
import SvgIcon from "components/Icon/SvgIcon";
import NoteSideBar from "./components/NoteSideBar";

// ** hooks **
import useNotesColumn from "./hooks/useNotesColumn";
import usePermission from "hooks/usePermission";

// ** services **
import { useAllTranscriptListAPI } from "./services";

// ** constants **
import { BREAD_CRUMB } from "constants/breadcrumbs.constant";
import { PERMISSIONS } from "constants/Permissions.constant";
import SelectMenu, { optionsType } from "components/SelectMenu";
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import AlertModal from "components/modal/AlertModal";
import {
  useAllDeleteTranscriptAPI,
  useDeleteTranscriptAPI,
} from "modules/Patient/sub-modules/Transcription/services";
import { useSelector } from "react-redux";
import { getAuth } from "redux/slices/authSlice";
import store from "redux/store";
import { removeToast, setToast } from "redux/slices/toastSlice";
import useNotesColumnSidebar from "./hooks/useNotesColumnSidebar";
import Button from "components/Theme/Button";

type currentNoteType = "patient" | "content" | "voice" | "meeting";

export const NoteOptions: optionsType[] = [
  {
    id: 1,
    title: "Patient",
    description: "",
  },
  {
    id: 2,
    title: "Content",
    description: "",
  },
  {
    id: 3,
    title: "Voice",
    description: "",
  },
  {
    id: 4,
    title: "Meeting",
    description: "",
  },
];

const NotesSidebar = (props: {
  currentNoteType?: currentNoteType;
  setSearchValue: any;
  searchData: string;
}) => {
  const { currentNoteType, setSearchValue, searchData } = props;
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const tableRef = useRef<TableRefType>(null);
  const [isSideOpen, setIsSideOpen] = useState<boolean>(false);
  const { state } = useLocation();
  const noteCreatePermission = hasPermission(PERMISSIONS.NOTES.CREATE);
  const [currentNote, setCurrentNote] = useState<currentNoteType>(
    currentNoteType ? currentNoteType : state?.note_type
  );
  const [open, setOpen] = useState(false);
  const [selectedIdsArr, setSelectedIdsArr] = useState<number[]>([]);
  const [currentID, setCurrentID] = useState<number | string>(0);
  const { getAllTranscriptListAPI, isLoading } = useAllTranscriptListAPI();
  const { deleteTranscriptAPI } = useDeleteTranscriptAPI();
  const { allDeleteTranscriptAPI } = useAllDeleteTranscriptAPI();
  const [throttleRecordCount, setThrottleRecordCount] = useState<number>(0);
  const hasNoteDeletePermission = hasPermission(PERMISSIONS.NOTES.DELETE);

  const { user } = useSelector(getAuth);
  const plan = user?.current_plan?.payment_data?.plan;
  const planName = plan?.plan_name?.toLocaleLowerCase();
  const specification = plan?.specification;
  let limit = 0;
  if (specification) {
    specification?.forEach((name: any, i) => {
      if (
        name?.name_type?.toLocaleLowerCase() === "transcript" &&
        name?.limit
      ) {
        limit = name?.limit;
        return;
      }
    });
  }
  if (planName === "premium") {
    limit = -1;
  }

  const getTableData = async (params: {
    page: number;
    perPage: number;
    sortField: string;
    sortOrder: string;
    search: string;
  }) => {
    let tableData = { rowData: [], rowCount: 10 };
    const { data, error } = await getAllTranscriptListAPI({
      params: {
        page: params.page,
        per_page: params.perPage,
        sort_by: params?.sortField,
        sort_order: params?.sortOrder,
        search: searchData ?? params.search,
        types: currentNote ?? "patient",
      },
    });

    if (data && !error) {
      setThrottleRecordCount(data?.usage_count);
      tableData = {
        rowData: data?.data || [],
        rowCount: data?.count || 0,
      };
    }
    return tableData;
  };

  const onDeleteClick = async (id: number | string) => {
    setOpen(true);
    setCurrentID(id);
  };
  const refreshTable = () => {
    tableRef.current?.refreshData();
  };

  useEffect(() => {
    if (state?.mode === "content" || state?.mode === "voice") {
      refreshTable();
    }
  }, [state]);

  const onDeleteSubmit = async (id: number | string) => {
    await deleteTranscriptAPI(id);
    refreshTable();
  };

  const onClose = () => {
    setOpen(false);
    setSelectedIdsArr([]);
    refreshTable();
  };

  const columnDefs = useNotesColumnSidebar({
    currentNote,
    tableRef,
    onDeleteClick,
    selectedIdsArr,
    setSelectedIdsArr,
  });
  useEffect(() => {
    const d = currentNoteType ? currentNoteType : state?.note_type;
    if (d) {
      setCurrentNote(d);
    }
  }, [currentNoteType, state]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    tableRef.current?.onChange?.(e);
  };

  useEffect(() => {
    setSearchValue(tableRef);
  }, [tableRef.current]);

  const dataTable = useCallback(() => {
    return (
      <DataTableReact
        setTableLength={null}
        key={currentNote ?? "patient"}
        ref={tableRef}
        columns={columnDefs}
        loader={isLoading}
        getData={(params: {
          page: number;
          perPage: number;
          sortField: string;
          sortOrder: string;
          search: string;
        }) => getTableData(params)}
      />
    );
  }, [currentNote, searchData, selectedIdsArr]);

  const onSelect = (value: optionsType) => {
    if (value?.title) {
      setCurrentNote(value?.title?.toLowerCase() as currentNoteType);
      navigate(PRIVATE_NAVIGATION.notes.view, {
        state: {
          note_type: value?.title?.toLowerCase() as currentNoteType,
          navHighlighter: value?.title?.toLowerCase() as currentNoteType,
        },
      }); // this navigate added to change active part of
    }
  };

  const onDeleteClickSidebar = async () => {
    setOpen(true);
  };

  const deleteHandler = async () => {
    if (selectedIdsArr?.length) {
      await allDeleteTranscriptAPI(selectedIdsArr);
      setSelectedIdsArr([]);
      refreshTable();
      navigate("/")
    }
  };

  return (
    <>
      <div>
        <NoteSideBar
          setIsSideOpen={setIsSideOpen}
          tableRef={tableRef}
          isSideOpen={isSideOpen}
          note_type={currentNote ?? "patient"}
        />
        {/* <div className="flex sm:flex-col items-center sm:items-start justify-between mt-[15px] mb-[15px]">
        <div className="flex items-center pr-[10px] ml-2 sm:ml-0"> */}
        {/* {(throttleRecordCount < +limit || limit === -1) && (
            <>
              {alert(
                "You exeeded the limit you won't be able to save the recording!!!"
              )}
            </>
          )} */}
        {/* {(throttleRecordCount < +limit || limit === -1)
          && */}
        {/* <SelectMenu
            publishingOptions={NoteOptions}
            onSelect={onSelect}
            title={noteCreatePermission ? "New Note" : undefined}
            titleIcon={
              noteCreatePermission ? (
                <Plus className="inline mx-1 size-sm"  strokeWidth="4" />
              ) : undefined
            }
            wantCheckInTitle={!noteCreatePermission}
            needHover={false}
            selected={{ id: null, title: currentNote ?? "patient" }}
            wantTickFromStatus={false}
            onTextClick={() => {
              if (+limit <= throttleRecordCount && +limit !== -1) {
                // onChange={(e) => {
                //   if (e.target.value.length <= 25) {
                //     onChangeTitle(e);
                //   } else {
                store.dispatch(removeToast({ id: 470000010 }));
                store.dispatch(
                  setToast({
                    message:
                      "Your Limit has been exhusted! Your Recording cannot Be Saved.",
                    type: "error",
                    id: 470000010,
                  })
                );
                setTimeout(() => {
                  store.dispatch(removeToast({ id: 470000010 }));
                }, 2000);
                //   }
                // }}
              }
              setIsSideOpen(true);
            }}
            titleDivClass="cursor-pointer"
          /> */}
        {/* } */}
        {/* </div> */}
        {/* </div> */}
        <div className="content-table content-table-data">
          <div
            className={`reactDataTable__DSD contentTable ${
              selectedIdsArr.length ? "data-check" : ""
            }`}
          >
            {dataTable()}
          </div>
        </div>
        <AlertModal
          visible={open}
          onClose={onClose}
          onCancel={onClose}
          onSubmit={() => {
            deleteHandler();
            setOpen(false);
          }}
          submitButtonText="Yes"
        >
          <p className="text-[18px] font-Biotif__SemiBold text-textDark text-center w-[300px] max-w-full mx-auto">
            {`Are you sure you want to delete ${
              (selectedIdsArr.length === 1 && "Recording") ||
              (selectedIdsArr.length > 1 && "Recordings")
            } ?`}
          </p>
        </AlertModal>
      </div>
      <div
        className={`${
          selectedIdsArr?.length && "p-3 border-t border-black/15"
        }`}
      >
        {selectedIdsArr?.length && hasNoteDeletePermission ? (
          <Button
            className="bg-white text-red-600 border border-red-600 w-48 hover:bg-red-400 hover:text-white hover:border-red-400"
            onClick={onDeleteClickSidebar}
          >
            Delete&nbsp;({selectedIdsArr?.length})
          </Button>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
export default NotesSidebar;
