import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PERMISSIONS } from "constants/Permissions.constant";
import usePermission from "hooks/usePermission";
import store from "redux/store";
import {
  AlertModalType,
  PromptColumnType,
  isOpenStateType,
} from "modules/Admin/sub-module/AdminPrompt/types/prompt.types";
import { MoreVertical } from "react-feather";
import TippyDropdown from "components/TippyDropDown";
import { renderOptions } from "components/TippyDropDown/helper";
import { usePromptOrderChangeAPI } from "modules/Admin/sub-module/AdminPrompt/services";
import { debounce } from "lodash";
import { string } from "yup";

type FilterParamsType = {
  page: number;
  perPage: number;
  sortField: string;
  sortOrder: string;
  search: string;
};

export interface RowData {
  id: string;
  name: string;
  first_name: string;
  last_name: string;
  types: string;
  created_at: string;
  created: {[key: string]: string};
  is_deactivate: boolean | null | undefined;
  is_draft: boolean | null | undefined;
  static: string | undefined | null;
  // Add other properties of your data
}

type SortHandler = (selectedColumn: string, sortDirection: string) => void;

type dataTableReactPropsType = {
  columns: PromptColumnType[] | any;
  getData:
    | ((params: FilterParamsType) => Promise<{
        rowData: Array<any>;
        rowCount: number;
        has_next: boolean;
      }>)
    | undefined;
  paginationProps?: boolean;
  loader: boolean;
  default_sortColumn?: string;
  default_asc?: boolean;
  className?: string;
  setTableLength: null | React.Dispatch<React.SetStateAction<number>>;
  onDelete?: (id: string | number) => void;
  onChangeStatus: (
    id: string | number,
    method: AlertModalType | null,
    status: string | null,
    isDefault: boolean | null
  ) => void;
  setIsOpen: React.Dispatch<React.SetStateAction<isOpenStateType>>;
  mainDivClassName: string;
};

export type TableRefType = {
  refreshData(): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
};

const reorder = (
  list: RowData[],
  startIndex: number,
  endIndex: number
): RowData[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableTable = forwardRef<TableRefType, dataTableReactPropsType>(
  (props, ref) => {
    const {
      columns,
      getData,
      default_sortColumn,
      default_asc = true,
      setTableLength = null,
      mainDivClassName = "",
    } = props;

    const [params, setParams] = useState({
      page: 1,
      perPage: 22,
      sortField: default_sortColumn ? default_sortColumn : "",
      sortOrder: default_asc ? "asc" : "desc",
      search: "",
    });
    const [totalData, setTotalData] = useState<{
      data: any[]; 
      rowCount: number;
      has_next: boolean;
    }>({ data: [], rowCount: 0, has_next: false });
    const hasNext = useRef(totalData?.has_next);

    const [items, setItems] = useState<RowData[]>([]);
    const [searchStart, setSearchStart] = useState<boolean>(false);
    const [sortOrder, setSortOrder] = useState<{
      sortField: string;
      sortOrder: string;
    }>({ sortField: "id", sortOrder: "asc" });

    const { hasPermission } = usePermission();
    const playbookUpdatePermission = hasPermission(
      PERMISSIONS.CUSTOM_PROMPT.UPDATE
    );

    const { getPromptOrderChangeApi, isLoading } = usePromptOrderChangeAPI();

    const handleSort: SortHandler = (sortField, sortDirection) => {
      setSortOrder({ sortField: sortField, sortOrder: sortDirection });
      if (sortField) {
        setParams({
          ...params,
          sortField: sortField,
          sortOrder: sortDirection,
        });
      }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchVal = e.target.value.trim();
      searchVal !== "" ? setSearchStart(true) : setSearchStart(false);
      setParams({ ...params, search: searchVal, page: 1 });
    };

    const dataTable = async () => {
      if (getData) {
        const { rowData, rowCount, has_next } = await getData(params);
        if (rowData) {
          const data: any[] =
            params.page === 1 ? rowData : [...totalData.data, ...rowData];
          hasNext.current = has_next;
          const count: number = rowCount;
          setTotalData({ data, rowCount: count, has_next: has_next });
          setItems(data);
          if (setTableLength !== null) {
            setTableLength(rowData?.length);
          }
        }
      }
    };

    const handlePageChange = (page: number) => {
      if (page > params.page && hasNext.current) {
        setParams({ ...params, page });
      }
    };

    const InfiniteScrollFn = (event: React.ChangeEvent<HTMLInputElement>) => {
      const bottom =
        event.target?.scrollHeight - event.target?.scrollTop ===
        event.target?.clientHeight;

      if (bottom && hasNext.current) {
        handlePageChange(params.page + 1);
      }
    };

    useEffect(() => {
      dataTable();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const onDragEnd = (result: any) => {
      if (playbookUpdatePermission) {
        if (
          result?.source?.droppableId === result?.destination?.droppableId &&
          result?.source?.index === result?.destination?.index
        ) {
          return;
        }
        if (!result.destination) {
          return;
        }
        const newItems = reorder(
          items,
          result.source.index,
          result.destination.index
        );
        getPromptOrderChangeApi(newItems);
        setItems(newItems);
      }
    };

    useImperativeHandle(ref, () => ({
      refreshData: () => {
        setParams({ ...params, page: 1 });
        dataTable();
      },
      onChange: (e) => onChange(e),
    }));

    const dataColumns = columns.filter((item: any) => {
      return item && item;
    });

    return (
      <div className={mainDivClassName}>
        <DragDropContext onDragEnd={debounce(onDragEnd, 100)}>
          <Droppable
            droppableId="draggable"
            isDropDisabled={searchStart || !playbookUpdatePermission}
          >
            {(provided: any) => (
              // <div className="min-w-full select-none grid grid-rows-[48px_auto] grid-template-columns-[minmax(150px,1fr)_minmax(150px,1fr)_minmax(150px,1fr)_minmax(200px,1fr)_80px_50px] ">
              <div>
                <div
                  className={`${
                    dataColumns[0]?.isWindowSizeTwo
                      ? "grid grid-cols-8 items-center sticky top-0 bg-white xxl:grid-cols-3 border-b border-gray-200 "
                      : "grid grid-cols-8 items-center sticky top-0 bg-white xxl:grid-cols-3 border-b border-gray-200 "
                  }`}
                >
                  {dataColumns.map((item: PromptColumnType) => {
                    return item?.showField ? (
                      <div
                        key={item?.name}
                        className={`${item?.mainClass} ${
                          item.name === "name" && "col-span-2"
                        }
                          ${
                            item.name === "created_by" &&
                            "col-span-2 overflow-hidden t ext-ellipsis"
                          }
                          ${
                            item.name === "action"
                              ? "text-center"
                              : "text-start"
                          }
                        `}
                        onClick={() => {
                          item?.sortAllow &&
                            handleSort(
                              item?.name,
                              sortOrder?.sortOrder !== "asc" ? "asc" : "desc"
                            );
                        }}
                      >
                        <div className={item?.childClass}>{item?.label}</div>
                      </div>
                    ) : (
                      <></>
                    );
                  })}
                </div>
                <div
                  className="overflow-y-auto row-start-2 col-span-full drag__wrapper h-[calc(100dvh-280px)]"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  onScroll={(e: React.ChangeEvent<HTMLInputElement>) => {
                    InfiniteScrollFn(e);
                  }}
                >
                  {items.map((row, index) => (
                    <Draggable
                      key={`${row?.id}-${index}`}
                      draggableId={`${row?.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${
                            dataColumns[0]?.isWindowSizeTwo
                              ? "grid grid-cols-8 items-center xxl:grid-cols-3 "
                              : "grid grid-cols-8 items-center xxl:grid-cols-3"
                          } border-b border-gray-200 whitespace-nowrap py-3 text-sm text-gray-500 hover:bg-gray-100`}
                        >
                          {dataColumns.map((item: PromptColumnType) => {
                            return (
                              <div
                                key={item?.name}
                                className={`${
                                  item.name === "name" &&
                                  "col-span-2 overflow-hidden text-ellipsis"
                                }
                                ${
                                  item.name === "created_by" &&
                                  "col-span-2 overflow-hidden t ext-ellipsis"
                                }
                                ${
                                  item.name === "action" &&
                                  "mx-auto xxl:ml-auto xxl:mr-[30px]"
                                }
                                `}
                              >
                                {item?.cell(row)}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
);

export default DraggableTable;
